export const SERVICES = [
  {
    name: 'Finance',
    slug: 'finance',
    thumbnail: 'https://images.unsplash.com/photo-1600132806370-bf17e65e942f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=994&q=20',
    thumbnail_alt: 'finance thumbnail',
    description: 'Finance Related',
  },
  {
    name: 'Human Resources & training',
    slug: 'human_resources_and_training',
    thumbnail: 'https://images.unsplash.com/photo-1600132806370-bf17e65e942f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=994&q=20',
    thumbnail_alt: 'Human resources thumbnail',
    description: 'HR Related',
  },
  {
    name: 'Writing & Editing',
    slug: 'writing_and_editing',
    thumbnail: 'https://images.unsplash.com/photo-1600132806370-bf17e65e942f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=994&q=20',
    thumbnail_alt: 'Writing and Editing',
    description: 'Writing and Editing',
  }
]
