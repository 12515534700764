import React from "react"
import { Typography } from '@mui/material'
import Page from '../components/Page'

export default function Services() {
  
  return (
    <Page title='Services'>
      <Typography align='Center' variant='h4' sx={{ mb: 5 }}>
        Services
      </Typography>
    </Page>
  )
}
