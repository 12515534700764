import * as React from 'react';
import { get } from 'lodash'
import {
  Avatar,
  Stack,
  Button,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import UserItem from './UserItem';
import ServiceRequestForm from '../sections/@dashboard/app/ServiceRequestForm';
import IconTextDateList from './reusables/IconTextDateList';

export const UserDetail = ({ user, handleClose }) => {
  const [open, setOpen] = React.useState(true)
  const [scroll, setScroll] = React.useState('paper')
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        scroll={scroll}
      >
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <DialogTitle id="scroll-dialog-title">
              Create Service Request
            </DialogTitle>
            <UserItem user={user}/>
            <Stack
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <div>
                <Divider/>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Request
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Advisory Session</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography mb={3}>
                      By requesting the booking, you are agreeing to the Expert's hourly rate of R {get(user, 'rate_per_hour_in_rands')}.
                    </Typography>
                    <ServiceRequestForm serviceProvider={user}/>
                  </AccordionDetails>
                </Accordion>
                <Divider/>
                <Accordion disabled expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>Reviews</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {<IconTextDateList/>}
                  </AccordionDetails>
                </Accordion>
              </div>
            </Stack>
            <Divider/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button> |
          <Button disabled>Report</Button> |
          <Button disabled>Request Service Request</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
